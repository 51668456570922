<script lang="ts">
    import { reporter } from '@felte/reporter-svelte'
    import { validator } from '@felte/validator-yup'
    import { Button, Feedback, Label, TextArea, TextField } from '@nomodo/ui'
    import { createForm } from 'felte'
    import { type InferType, object, string } from 'yup'

    interface Props {
        loading?: boolean
        submit: (
            v: CustomEvent<{ email: string; subject: string; note: string }>
        ) => void
    }

    const { loading = false, submit }: Props = $props()

    const schema = object().shape({
        email: string().email().required(),
        subject: string().required(),
        note: string().required(),
    })
    const { form, data, errors, touched } = createForm<
        InferType<typeof schema>
    >({
        initialValues: { email: '', subject: '', note: '' },

        extend: [validator({ schema }), reporter],
        onSubmit: (v) => {
            const e = new CustomEvent('submit', { detail: v })
            submit(e)
        },
    })
</script>

<form use:form>
    <div class="mb-4">
        <Label class="mb-2" for="email">Your email *</Label>
        <TextField
            id="email"
            name="email"
            autocomplete="email"
            invalid={!!$errors.email}
            type="email"
            valid={!$errors.email && $touched.email}
            value={$data.email}
        />
        {#if $errors.email}
            <Feedback invalid>{$errors.email}</Feedback>
        {/if}
    </div>

    <div class="mb-4">
        <Label class="mb-2" for="email">Subject *</Label>
        <TextField
            id="subject"
            name="subject"
            invalid={!!$errors.subject}
            valid={!$errors.subject && $touched.subject}
            value={$data.subject}
        />
        {#if $errors.subject}
            <Feedback invalid>{$errors.subject}</Feedback>
        {/if}
    </div>

    <div class="mb-4">
        <Label class="mb-2" for="note">Note *</Label>
        <TextArea
            id="note"
            name="note"
            invalid={!!$errors.note}
            valid={!$errors.note && $touched.note}
            value={$data.note}
        />
        {#if $errors.note}
            <Feedback invalid>{$errors.note}</Feedback>
        {/if}
    </div>

    <Button class="w-3/4" {loading} type="submit" variant="success">
        Submit
    </Button>
</form>
